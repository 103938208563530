.block-slideshow {
  height: auto;
  overflow: hidden;
}
.block-slideshow .item {
  height: auto;
  position: relative;
}
.block-slideshow .item img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.block-slideshow .item video {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.block-slideshow .slick-prev {
  left: 15px;
}
.block-slideshow .slick-next {
  right: 35px;
}
.block-slideshow .slick-prev, .block-slideshow .slick-next {
  z-index: 9;
}
.block-slideshow .slick-dots {
  bottom: -26px;
}
.block-slideshow .slick-dots li button:before {
  opacity: 1;
  color: #ccc;
}
.block-slideshow .slick-dots.dots-vertical .slick-dots {
  transform: rotate(90deg);
  width: auto;
  top: 20px;
  right: 0;
  left: auto;
}
.block-slideshow .slick-dots.dots-bellow .slick-dots {
  --text-color:#222;
  top: auto;
  bottom: calc(-1 * var(--margin-inside));
}

.slick-slide a:focus {
  outline: none;
}

.block-slideshow .slick-dots li.slick-active button:before {
  font-size: 12px;
  color: #8b8b8b;
}